:root {
    --white: #fff;
    --google-blue: #4285f4;
    --button-active-blue: #1669F2;
}

.google-btn {
    width: 184px;
    height: 42px;
    background-color: var(--google-blue);
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: var(--white);
}

.google-btn .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
}

.google-btn .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: var(--white);
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
}

.google-btn:hover {
    box-shadow: 0 0 6px var(--google-blue);
}

.google-btn:active {
    background: var(--button-active-blue);
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);